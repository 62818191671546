import AppBanners from "@components/AppBanners";
import { AnyEvent } from "@utils/AnyEvent";
import { getWindow } from "@utils/window";
import React, {
  FC,
  Fragment,
  SyntheticEvent,
  useCallback,
  useState,
} from "react";
import { useEffect } from "react";
import { Button, Card, Container, Form, FormGroup } from "react-bootstrap";

type Props = {
  qrCodeId: string;
  facilityLabel: string | null;
  mailto: string;
  _isDev: boolean;
};
export type AdvanceCheckInFormProps = Props;

const AdvanceCheckInForm: FC<Props> = ({
  qrCodeId,
  facilityLabel,
  mailto,
  _isDev,
}) => {
  if (!mailto) throw new Error("Invalid mailto");
  const windowOpen = getWindow((w) => w.open);

  const onInputComplete = useCallback(
    ({ applicant, date }: InputData) => {
      const url = _getCheckInFullUrl({
        qrCodeId,
        mailto,
        applicant,
        date,
        _isDev,
      });
      windowOpen?.(url, "_self");
      // deep link を _blank で開くとAndroidで機能しないので _self で開きます。
    },
    [qrCodeId, mailto, _isDev, windowOpen]
  );

  return (
    <Fragment>
      <Card>
        <Card.Header>
          {(facilityLabel &&
            `「${facilityLabel}」の事前申請書提出のための情報を入力してください。`) ||
            "事前申請書提出に必要情報を入力してください"}
        </Card.Header>
        <Card.Body className="mt-3">
          <InputForm onComplete={onInputComplete} />
        </Card.Body>
      </Card>
      <Container className="mt-3">
        <Form.Text className="text-muted">
          まだWan!Passアプリをお持ちでない方は、以下のボタンからアプリをダウンロードし、会員登録とワクチン接種等の証明書をご登録ください。
        </Form.Text>
        <div className="mt-3">
          <AppBanners />
        </div>
      </Container>
    </Fragment>
  );
};

export default AdvanceCheckInForm;

const _preventDefaultCallback = (e: SyntheticEvent) => {
  e.preventDefault();
};

type InputData = { date: string; applicant: string };
type InputFormProps = { onComplete: (data: InputData) => void };
const InputForm: FC<InputFormProps> = ({ onComplete }) => {
  const [data, setData] = useState<InputData>({ date: "", applicant: "" });
  const [dateError, setDateError] = useState<string | null>(null);

  const onClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      onComplete(data);
    },
    [data]
  );

  const [buttonEnabled, setButtonEnabled] = useState(false);
  useEffect(() => {
    setButtonEnabled(!!(data.applicant && data.date && !dateError));
  }, [data, dateError]);

  const onDateChange = useCallback(
    (e: AnyEvent) => {
      const newDate = e.target.value;
      setData({ ...data, date: newDate });

      setDateError(null);
      if (newDate) {
        const error = _getDateErrorMessage(newDate);
        if (error) setDateError(error);
      }
    },
    [data]
  );
  const onApplicantChange = useCallback(
    (e: AnyEvent) => {
      setData({ ...data, applicant: e.target.value });
    },
    [data]
  );

  return (
    <Fragment>
      <Form onSubmit={_preventDefaultCallback}>
        <Form.Group className="mb-3">
          <Form.Label>ご利用予定日</Form.Label>
          <Form.Control type="date" onChange={onDateChange} />
          <Form.Text className="text-muted">
            施設をご利用になる日付を入力してください。
            {dateError && <div style={{ color: "red" }}>{dateError}</div>}
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>ご利用者のお名前</Form.Label>
          <Form.Control type="text" onChange={onApplicantChange} />
          <Form.Text className="text-muted">
            施設をご利用になるお客様の代表者様の氏名をご入力ください。
          </Form.Text>
        </Form.Group>

        <FormGroup className="mb-3">
          <Form.Text className="text-muted">
            Wan!Passアプリを開いて、事前申請書提出を完了させてください。
          </Form.Text>
          <Button
            className="ms-auto d-block mt-3"
            variant="primary"
            onClick={onClick}
            disabled={!buttonEnabled}
          >
            Wan!Passアプリを開く
          </Button>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

const _getDateErrorMessage = (date: string): string | null => {
  const today = new Date().toISOString().slice(0, 10);
  if (!date.match(/^20[0-9]{2}-[0-9]{2}-[0-9]{2}$/))
    return "正しい日付を入力してください。";
  if (date < today)
    return "ご利用を予定している【本日以降の日付】を入力してください。";
  return null;
};

type EntryUrlParams = {
  qrCodeId: string;
  mailto: string;
  applicant: string;
  date: string;
  _isDev: boolean;
};

const _getCheckInFullUrl = ({
  qrCodeId,
  mailto,
  applicant,
  date,
  _isDev,
}: EntryUrlParams) => {
  const fqdn = (_isDev ? "dev." : "") + "entry.wanpass.me";
  const url = new URL(`https://${fqdn}/checkin/${qrCodeId}/advance`);
  url.searchParams.append("mailto", mailto);
  url.searchParams.append("date", date);
  url.searchParams.append("applicant", applicant);
  return url.toString();
};
