import AdvanceCheckInForm, {
  AdvanceCheckInFormProps,
} from "@components/owner/AdvanceCheckInForm";
import ToolLayout from "@layouts/ToolLayout";
import { getWindow } from "@utils/window";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { Container } from "react-bootstrap";

/**
 * 事前申請書提出の入力フォームです。
 * entry.wanpass.me/checkin/${id}/advance からリダイレクトでここにきます。
 *
 * ローカルでの確認用URL:
 * http://localhost:8000/owner/advance_check_in?id=943ad9ec-5da0-485c-805b-f2b4e301c738&mailto=hotel%40example.com&label=%E5%B1%B1%E5%85%83%E3%83%9B%E3%83%86%E3%83%AB&dev=1
 */
const AdvanceCheckInPage = () => {
  const { qrCodeId, facilityLabel, mailto, _isDev } = useDefaultsByQuery();
  if (!mailto || !qrCodeId) return <InvalidUrlWarning />;

  const formProps: AdvanceCheckInFormProps = {
    qrCodeId,
    facilityLabel,
    mailto,
    _isDev,
  };
  return (
    <ToolLayout title={`Wan!Pass（ワンパス） 事前申請書提出`} noIndexNoFollow>
      <Container fluid style={{ maxWidth: 375, padding: 0 }}>
        <AdvanceCheckInForm {...formProps} />
      </Container>
      {_isDev && <DevDebugMessage />}
    </ToolLayout>
  );
};
export default AdvanceCheckInPage;

const useDefaultsByQuery = () => {
  const locationSearch = getWindow((w) => w.location.search);
  const defaults = React.useMemo(() => {
    const params = new URLSearchParams(locationSearch || "");
    // See: wanpass-backend: src/entry_server.ts _getAdvanceFormUrl
    const qrCodeId = params.get("id");
    const facilityLabel = params.get("label");
    const mailto = params.get("mailto");
    const _isDev = params.get("dev") === "1";

    return {
      qrCodeId,
      facilityLabel,
      mailto,
      _isDev,
    };
  }, [locationSearch]);
  return defaults;
};

const DevDebugMessage = () => {
  const alert = getWindow((w) => w.alert);
  const onClick = useCallback(() => {
    alert?.(
      "URLに「dev=1」が含まれるため、dev環境用に作ったページです。「Wan!Passアプリを開く」ボタンを押すとdev環境向けのアプリを開きます。"
    );
  }, []);
  return (
    <Container style={{ textAlign: "center", color: "gray" }}>
      <span onClick={onClick} style={{ cursor: "pointer" }}>
        ⚠ここは検証環境用です⚠
      </span>
    </Container>
  );
};

const InvalidUrlWarning = () => {
  const [visible, setVisible] = useState(false);

  // location.search を読み込むのに時間がかかる場合があるので、遅延表示します。
  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  if (!visible) return <></>;
  return <>このURLは無効です。URLに施設側の情報が含まれていません。</>;
};
